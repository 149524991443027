import React, {memo} from 'react';
import {Row} from 'rsuite';

import ShowMessage from './ShowMessage';



const Chat = (props) => {
    console.log("Chat");
    // const [isLoading, setisLoading] = useState(false);

    return (
        <ShowMessage ismobile={props.ismobile}/>
    )
}

export default memo(Chat);