import React, {memo, useEffect} from 'react';
import {connect} from 'react-redux';
import InputMessage from './InputMessage';
import Chat from './Chat';

const MessageComponent = (props) => {
    console.log("MessageComponent");

    useEffect(() => {

    }, [props.userid])

    return props.userid !== "" ? (
        <div>
            <Chat ismobile={props.ismobile}/>
            <InputMessage/>
        </div>
    ) : (
        <div style={{textAlign:'center'}}>
            <img
                src="/img/isometric/Chat_PNG.png"
                style={{width:400, top:40, position: 'relative'}}
                alt="No Chat"
            />
        </div>
    )
}

const mapStateToProps = ({chat}) => {
    return {
        userid : chat.userid
    }
}

export default connect(mapStateToProps, null)(memo(MessageComponent));