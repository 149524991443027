import firebase from 'config/firebase';
import store from 'config/stores';

export const getContactList = (name = null) => {
    return dispatch => {
        // dispatch({
        //     type : "CLEAR_CONTACT"
        // })
        firebase.database()
            .ref("contact/")
            .on("value", contacts => {
                firebase.database()
                    .ref("last-chat/")
                    .orderByChild("lastChatTimeMin")
                    .on("value", lastChats => {
                        let lastChatsData = {};
                        lastChats.forEach(function(child) {
                            Object.assign(lastChatsData, {[child.key] : child.val()})
                        });
                        const contactsData = contacts.val();
                        let lastChatList = [];
                        Object.keys(lastChatsData).map((lastChatKey) => {
                            let found = false;
                            let lastChatObj = {
                                lastChatKey : "",
                                lastChatTime : 0,
                                lastChatTimeMin : 0,
                                photo : "",
                                name : "",
                                phone : "",
                                unread : 0 
                            }
                            Object.keys(contactsData).map(contactKey => {
                                if(lastChatsData[lastChatKey].phone === contactsData[contactKey].phone){
                                    found = true;
                                    lastChatObj.lastChatKey = lastChatKey;
                                    lastChatObj.lastChatTime = lastChatsData[lastChatKey].lastChatTime;
                                    lastChatObj.lastChatTimeMin = lastChatsData[lastChatKey].lastChatTimeMin;
                                    lastChatObj.lastChatMessage = lastChatsData[lastChatKey].lastChatMessage;
                                    lastChatObj.photo = lastChatsData[lastChatKey].photo;
                                    lastChatObj.name = contactsData[contactKey].name;
                                    lastChatObj.phone = lastChatsData[lastChatKey].phone;
                                    lastChatObj.unread = lastChatsData[lastChatKey].unreadCount;
                                    delete contactsData[contactKey];
                                }
                                return 1;
                            })
                            if(found){
                                lastChatList.push(lastChatObj);
                            } else {
                                lastChatList.push({
                                    lastChatKey : lastChatKey,
                                    lastChatTime : lastChatsData[lastChatKey].lastChatTime,
                                    lastChatTimeMin : lastChatsData[lastChatKey].lastChatTimeMin,
                                    lastChatMessage : lastChatsData[lastChatKey].lastChatMessage,
                                    photo : "",
                                    name : lastChatsData[lastChatKey].name,
                                    phone : lastChatsData[lastChatKey].phone,
                                    unread : lastChatsData[lastChatKey].unreadCount,
                                })
                            }
                            found = false;
                            return 1;
                        })
                        if(name !== null && name !== ""){
                            Object.keys(contactsData).map(contactKey => {
                                lastChatList.push({
                                    lastChatKey : contactKey,
                                    lastChatTime : 0,
                                    lastChatTimeMin : 0,
                                    photo : contactsData[contactKey].photo,
                                    name : contactsData[contactKey].name,
                                    phone : contactsData[contactKey].phone,
                                    unread : 0
                                })
                                return 1;
                            })
                            const contactList = lastChatList.filter(v => {
                                if(v.name !== undefined){
                                    if(v.name.toLowerCase().match(name.toLowerCase())) return v
                                }
                            })
                            dispatch({
                                type : "FETCH_CONTACT",
                                payload : contactList
                            })
                        }
                        else {
                            dispatch({
                                type : "FETCH_CONTACT",
                                payload : lastChatList
                            })
                        }
                    })
            })
    }
}

export const searchContactAndChat = (name = null) => {
    return dispatch => {
        if(name === null){
            firebase.database()
            .ref("contact/")
            .orderByChild("message")
            .equalTo("uniq")
            .once("value", snapshot => {
                const data = snapshot.val();
            })
        } else {
            getContactList(name);
        }
    }
}

export const clearChatFromView = () => {
    return {
        type : "CLEAR_CHAT_FROM_VIEW"
    }
}

export const userInfo = (userid, phone) => {
    return {
        type : "FETCH_USER_NAME_INFO",
        payload : {
            userid : userid,
            phone :  phone
        }
    }
}

export const showChat = () => {
    return {
        type : "SHOW_CHAT"
    }
}

export const hideChat = () => {
    return {
        type : "HIDE_CHAT"
    }
}

export const showImage = (url) => {
    return {
        type : "SHOW_IMAGE",
        url : url
    }
}

export const hideImage = () => {
    return {
        type : "HIDE_IMAGE"
    }
}

export const getChatList = (userid) => {
    return dispatch => {
        const state = store.store.getState();
        if(state.chat.userid !== "" && state.chat.userid !== userid){
            dispatch(destroyChatList(state.chat.userid));
        }
        const contactList = state.contact;
        let contactname = "";
        Object.keys(contactList).map((data) => {
            if(contactList[data].lastChatKey === userid){
                contactname = contactList[data].name ? contactList[data].name : contactList[data].phone;
            } 
            return 0;
        })
        dispatch(userInfo(contactname, userid));
        dispatch(showChat());
        firebase
            .database()
            .ref(`chat/${userid}/`)
            .orderByChild("timeMillis")
            // .limitToLast(1)
            .on("value", snapshot => {
                let snapShot = {};
                let newChatData = {};
                snapshot.forEach((val) => {
                    let data = val.val();
                    if(Object.entries(state.chat.data).length !== 0){
                        if(!Object.keys(state.chat.data).map((data, i) => {
                            return data === val.key ? true : false;
                        }) && 
                        Object.keys(state.chat.data).map((data, i) => {
                            if(state.chat.data[data].timeMillis === null){
                                delete state.chat.data[data].timeMillis;
                                return true;
                            } else {
                                return false;
                            }
                        })){
                            if(data.userType !== "customer" || (data.userType === "customer" && userid === data.sender)){
                                Object.assign(newChatData, {
                                    [val.key] : data
                                })
                            }
                        }
                    } else {
                        if(data.userType !== "customer" || (data.userType === "customer" && userid === data.sender)){
                            Object.assign(snapShot, {
                                [val.key] : data
                            })
                        }
                    }
                });
                if(Object.entries(state.chat.data).length === 0){
                    dispatch({
                        type : "FETCH_USER_CHAT",
                        lastChat : false,
                        data : snapShot
                    });
                } else {
                    dispatch(appendNewChat(newChatData));
                }
            })
    }
}

export const appendNewChat = (data) => {
    return {
        type: "APPEND_NEW_USER_CHAT",
        lastChat : false,
        data : data
    }
}

export const destroyChatList = (userid) => {
    return dispatch => {
        dispatch(clearChatFromView());
        firebase
            .database()
            .ref(`chat/${userid}/`)
            .orderByChild("timeMillis")
            // .limitToLast(25)
            .off("value")
    }
}

export const getNextChat = (userid) => {
    return dispatch => {
        const state = store.store.getState();
        const lastChat = state.chat.lastChat;
        if(lastChat === false){
            const lastKey = Object.keys(state.chat.data)[0];
            firebase
                .database()
                .ref(`chat/${userid}/`)
                .orderByChild("timeMillis")
                .endAt(lastKey)
                // .limitToLast(26)
                .once("value", snapshot => {
                    let lastChat = true;
                    if(Object.keys(snapshot.val()).length > 25) lastChat = false;
                    dispatch({
                        type : "FETCH_NEXT_CHAT",
                        lastChat : lastChat,
                        data : {
                            val : snapshot.val()
                        }
                    })
                })
        }

    }
}

export const getNewChat = (userid) => {
    return dispatch => {
        // firebase
        //     .database()
        //     .ref(`chat/${userid}`)
        //     .limitToLast(1)
        //     .on("child_added", snapshot => {
        //         console.log(snapshot.val());
        //         dispatch({
        //             type : "GET_USER_NEW_CHAT",
        //             data : {
        //                 [snapshot.key] : {...snapshot.val()}
        //             }
        //         })
        //     })
    }
}

export const sendMessage = (userid, message) => {
    return dispatch => {
        console.log(message);
        console.log(userid);
        const userAuth = firebase.auth();
        const phone = userAuth.currentUser.phoneNumber;
        firebase
            .database()
            .ref(`chat/${userid}/`)
            .push ({
                message: `${message}\n\n_cs: ${localStorage.getItem("csName")}`,
                sender : phone,
                timeMillis : new Date().getTime(),
                timeMillisMin : -new Date().getTime(),
                userType : 'cs',
            });
        firebase
            .database()
            .ref(`last-chat/${userid}/`)
            .set({
                lastChatMessage : `${message}\n\n_cs: ${localStorage.getItem("csName")}`,
                lastChatTime : new Date().getTime(),
                lastChatTimeMin : -new Date().getTime(),
                phone : userid
            })
    }
}

export const sendImage = (userid, file, message) => {
    return dispatch => {
        const userAuth = firebase.auth();
        const phone = userAuth.currentUser.phoneNumber;
        const upload = firebase.storage()
            .ref(`chat-image/${userid}/`).child(`${file.name}`).put(file);
        upload.on("state_changed", (snapshot) => {
            console.log(snapshot);
        }, (error) => {
            dispatch({
                type : "ERROR_UPLOAD_IMAGE"
            })
        }, () => {
            upload.snapshot.ref.getDownloadURL().then(url => {
                console.log(url);
                console.log(userid);
                console.log(message);
                console.log(phone);
                firebase.database()
                    .ref(`chat/${userid}/`)
                    .push({
                        imgUrl : url,
                        message : message,
                        sender : phone,
                        timeMillis : new Date().getTime(),
                        timeMillisMin : -new Date().getTime(),
                        userType : "cs"
                    })
            })
        })
    }
}

export const showComponentContact = () => {
    return {
        type : "showContact"
    }
}


export const showComponentChat = () => {
    return {
        type : "showChat"
    }
}

const showContactListDispatcher = (data) => {
    return {
        type : "SHOW_CONTACT_LIST",
        payload : data
    }
}

const addContactToContactListDispatcher = (data) => {
    return {
        type : "ADD_CONTACT_TO_CONTACT_LIST",
        payload : data
    }
}

export const showContactList = () => {
    return dispatch => {
        firebase.database()
            .ref("contact/")
            .once("value", contacts => {
                const contactList = contacts.val();
                const state = store.store.getState();
                if(Object.entries(state.listContactInDb).length === 0){
                    dispatch(showContactListDispatcher(contactList));
                } else if(Object.entries(state.listContactInDb).length !== Object.entries(contactList).length){
                    // Object.keys(contactList).map((contact, i) => {
                    //     Object.keys(state.listContactInDb).map((list, j) => {
                    //         if(contact !== list){

                    //         }
                    //     })
                    //     dispatch(addContactToContactListDispatcher({
                        
                    //     }));
                    // })
                }
            })
    }
}