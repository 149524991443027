import React, {memo} from 'react';
import {Header, Icon} from 'rsuite';
import {connect} from 'react-redux';
import * as authAction from 'containers/LandingPage/actions/index';

const MessageHeader = (props) => {
    console.log("MessageHeader");
    return (
        props.isMobile === true ? (
            <Header style={{background: "#18191a", height: "8vh", boxShadow: " 0px 0px 6px 0px rgba(0,0,0,0.75)", MozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)", WebkitBoxShadow : "0px 0px 6px 0px rgba(0,0,0,0.75)"}}>
                {
                    props.showComponentContact === true ? 
                    (
                        <div style={{width : "100%", float: "left",padding: 10, }}>
                            <div style={{float: "left", width: "50%"}}><b>UNIQ</b></div>
                            <div style={{float: "right"}}>
                                <div style={{cursor : "pointer", display: "inline-block", marginLeft: 10}} onClick={props.addChat}>
                                    <Icon icon="plus" />
                                </div>
                                <div style={{cursor : "pointer", display: "inline-block", marginLeft: 10}} onClick={() => props.LOGOUT()}>
                                    <Icon icon="sign-out"/>    
                                </div>
                            </div>
                        </div>
                    ) : 
                    (
                        <div style={{width: "100%", float: "left", padding: 10, }}>
                            <div style={{cursor : "pointer", display: "inline-block", marginRight: 20}} onClick={() => props.SHOW_CONTACT()}>
                                <Icon icon="chevron-left"/>    
                            </div>
                            {props.userid}
                        </div>
                    )
                }
            </Header>
        ) : (
            <Header style={{background: "#18191a", height: "6vh", boxShadow: " 0px 0px 6px 0px rgba(0,0,0,0.75)", MozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)", WebkitBoxShadow : "0px 0px 6px 0px rgba(0,0,0,0.75)"}}>
                <div style={{width : "30%", float: "left",padding: 10, }}>
                    <div style={{float: "left", width: "50%"}}><b>UNIQ</b></div>
                    <div style={{float: "right"}}>
                        <div style={{cursor : "pointer", display: "inline-block", marginLeft: 10}} onClick={props.addChat}>
                            <Icon icon="plus" />
                        </div>
                        <div style={{cursor : "pointer", display: "inline-block", marginLeft: 10}} onClick={() => props.LOGOUT()}>
                            <Icon icon="sign-out"/>    
                        </div>
                    </div>
                </div>
                <div style={{width: "70%", float: "left", padding: 10, }}>
                    {props.userid}
                </div>
            </Header>
        )
    )
}

const mapStateToProps = ({chat, dashboard}) => {
    return {
        userid : chat.userid,
        showComponentContact : dashboard.showContact,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        LOGOUT : () => dispatch(authAction.Logout()),
    }
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(MessageHeader));