import React, {memo, useEffect, Suspense} from 'react';
import {connect} from 'react-redux';
import {Modal, Loader} from 'rsuite';

import ContactList from '../components/ContantList';
import * as action from '../action/index';
const ModalContacts = (props) => {
    console.log("ModalContacts");
    useEffect(() => {
        setTimeout(() => {
            if(props.searchContactShow === true){
                props.showContacts();
            }
        }, 1000);
    }, [props]);
    
    return (
        <Modal  size="xs" show={props.searchContactShow} onHide={props.onHide}>
            <Modal.Header>
                <Modal.Title>Contacts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Suspense fallback={<div style={{minHeight : "100vh", position: "relative", top : "30vh", left: "45%"}}><Loader size="lg" /></div>}>
                {
                    Object.keys(props.contacts).map((data) => {
                        return (
                            <ContactList 
                                name={props.contacts[data].name} 
                                phone={props.contacts[data].phone} 
                                photo={props.contacts[data].photo} 
                                key={data}
                            />
                        )
                    })
                }
                </Suspense>
            </Modal.Body>
            <Modal.Footer>
                
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = ({listContactInDb}) => {
    return {
        contacts : listContactInDb
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showContacts : () => dispatch(action.showContactList())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(ModalContacts));