import React from 'react';

import App from './App';
import { MemoryRouter as Router} from 'react-router-dom';
// import {registerObserver} from 'react-perf-devtool';

import 'rsuite/dist/styles/rsuite.min.css';

import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
// import store 
import store from 'config/stores/';

const Bootstrapper = () => {
    return (
        <Provider store={store.store}>
            <PersistGate loading={null} persistor={store.persistor}>
                <Router>
                    <App/>
                </Router>
            </PersistGate>
        </Provider>
    )
}

export default Bootstrapper;