import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { 
    Container, Grid, Row, Alert, 
} from 'rsuite';
import firebase from 'config/firebase'
import InputPhone from '../components/inputPhone';
import InputVerif from '../components/InputVerif';
import '../styles/wave.css';

import {connect} from 'react-redux';
import {login} from '../actions';

const pattern = /^\+(?:[0-9] ?){6,14}[0-9]$/g;

class LoginPage extends Component{
    constructor() {
        super()
        this.state = {
            paddingBottom : String,
            userData : {
                phone : String,
                code : String,
            },
            notif : {
                phoneNotif : "none"
            },
            component: {
                InputPhone : "block",
                InputCode : "none"
            },
            isInputPhoneDisabled : false,
            confirmation : undefined,
            isMobile : false,
        }
        this._onSubmit = this._onSubmit.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changeCode = this.changeCode.bind(this);
        this._sendVerificationCode = this._sendVerificationCode.bind(this);
        this.cancelVerification = this.cancelVerification.bind(this);
    }
    
    componentDidMount(){
        console.log("Berubah")
        firebase.auth().languageCode = 'id';
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container",{
            'size':"invisible"
        });
        window.recaptchaVerifier.render().then(function(widgetId) {
            window.recaptchaWidgetId = widgetId;
        });
        window.addEventListener('resize',  this.checkIsMobileDevice.bind(this));
        this.checkIsMobileDevice();
    }

    clearChapcha(){
        if (typeof grecaptcha !== 'undefined' && typeof window.recaptchaWidgetId !== 'undefined') {
            // this.recaptchaVerifier.clear()
            // this.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
            window.recaptchaVerifier.reset(window.recaptchaWidgetId);
        }
    }

    changeCode(code){
        const tmpUser = this.state.userData;
        tmpUser.code = code;
        this.setState({
            ...this.state,
            tmpUser
        })
    }

    changePhone(phone){
        const tmpUser = this.state.userData;
        tmpUser.phone = phone;
        phone = `+62${phone}`;
        const notif = this.state.notif;
        notif.phoneNotif = "none";
        this.setState({
            ...this.state,
            tmpUser,
            // isInputPhoneDisabled : false,
        })
        if(this.state.userData.phone.length > 0){
            this.setState({
                ...this.state,
                notif
            })
        }
    }

    async _onSubmit(e){
        e.preventDefault();
        const phone = `+62${this.state.userData.phone}`;
        const notif = this.state.notif;
        if(!pattern.test(phone)){
            notif.phoneNotif = "inline";
            this.setState({
                ...this.state,
                notif
            })
        } else {
            this.setState({
                isInputPhoneDisabled : true
            })
            const phoneNumber = `+62${this.state.userData.phone}`;
            const users = await firebase
                .firestore()
                .collection("customer_support")
                .where("phone", "array-contains", phoneNumber.replace("+",""))
                .get();
            users.docs.forEach(val => {
                let user = val.data()
                console.log(user.name);
                localStorage.setItem("csName", user.name);
            })
            console.log(users.docs)
            if(users.size > 0){
                console.log("users.size");
                console.log(users.size);
                const component = this.state.component;
                component.InputCode = "block";
                component.InputPhone = "none";
                this.setState({
                    ...this.state,
                    component,
                })
                firebase.auth().signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
                    .then(function (confirmationResult) {
                        window.confirmationResult = confirmationResult;
                        window.signingIn = false;
                        
                    }).catch(function (error) {
                        console.error('Error during signInWithPhoneNumber', error);
                    });
            } else {
                // this.clearChapcha();
                Alert.error("User Not Found", 4000);
                this.setState({
                    isInputPhoneDisabled : false
                })
            }
        }
    }

    cancelVerification(){
        const component = this.state.component;
        window.confirmationResult = null;
        component.InputCode = "none";
        component.InputPhone = "block";
        this.setState({
            ...this.state,
            component,
            isInputPhoneDisabled : false,
        })
        this.clearChapcha()
    }

    async _sendVerificationCode(e){
        window.verifyingCode = true;
        const props = this.props;
        window.confirmationResult.confirm(this.state.userData.code).then(function (result) {
            // User signed in successfully.
            // var user = result.user;
            // let credential = firebase.auth.PhoneAuthProvider.credential(result.verificationId, this.state.userData.code);
            // firebase.auth().signInWithCredential(credential);
            window.verifyingCode = false;
            window.confirmationResult = null;
            props.auth();
            props.history.push("/");
            // this.clearChapcha();
            // updateVerificationCodeFormUI();
          }).catch(function (error) {
            // User couldn't sign in (bad verification code?)
            console.error('Error while checking the verification code', error);
            // updateSignInButtonUI();
            Alert.error("Error while checking the verification code", 5000);
            // updateVerifyCodeButtonUI();
          });
        // await this.state.confirmation.confirm(this.state.userData.code);
    }

    async checkIsMobileDevice(){
        const isMobile = window.innerWidth <= 500;
        if(isMobile){
            if(window.innerWidth <= 320){
                this.setState({
                    paddingBottom : '32vh'
                })
            } else {
                this.setState({
                    paddingBottom : '45vh'
                })
            }
            this.setState({
                isMobile : true
            })
        } else {
            this.setState({
                paddingBottom : '27vh',
                isMobile : false
            })
        }
    }

    

    render(){
        console.log("Terpaingil reder");
        return(
            <Container style={{background: '#222', overflow:'hidden', height:'100vh', paddingTop: this.state.paddingBottom, color: 'white'}}>
                <Grid fluid>
                    <Row className="show-grid">
                        <InputPhone mobile={this.state.isMobile} show={this.state.component.InputPhone} changePhone={this.changePhone} notif={this.state.notif} _onSubmit={this._onSubmit} disabled={this.state.isInputPhoneDisabled}/>
                        <InputVerif mobile={this.state.isMobile} show={this.state.component.InputCode} changeCode={this.changeCode} code={this.state.userData.code} _onCancel={this.cancelVerification} _onVerify={this._sendVerificationCode}/>
                    </Row>
                </Grid>
                <div ref={ref => this.recaptchaWrapperRef = ref}>
                    <div id="recaptcha-container"></div>
                </div>
            </Container>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth : () => dispatch(login())
    }
}

export default connect(null, mapDispatchToProps)(withRouter(LoginPage));