import React, {useState, memo} from 'react';
import {
    Form, FormGroup, InputGroup, Button, Col, Input
} from 'rsuite'

const InputVerif = (props) => {
    const [isDisable, setIsDisable] = useState(true);
    const isDisabled = () => {
        if(props.code.length === 5){
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }
    const widthComponent = props.mobile === true ? "100%":400
    return (
        <div style={{display:props.show}}>
            <Col md={24} xs={24} sm={24} style={{ zIndex: 2, width:widthComponent}}>
                <Col md={24} xs={24} sm={24}>
                    <Button appearance="primary" id="onCancleSubmit" onClick={props._onCancel} style={{float: "left", zIndex: 2, background: "none", fontWeight: "bold", fontSize: '13pt'}}>Cancel</Button>
                    <Button appearance="primary" id="onSubmit" onClick={props._onVerify} style={{float: "right", zIndex: 2, background: "none", fontWeight: "bold", fontSize: '13pt'}} disabled={isDisable}>Sign in</Button>
                </Col>
                <Col md={24} xs={24} sm={24} style={{background: '#222', padding: '50px', borderRadius: "5px", boxShadow: " 0px 0px 6px 0px rgba(0,0,0,0.75)", MozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)", WebkitBoxShadow : "0px 0px 6px 0px rgba(0,0,0,0.75)"}}>
                    <p style={{fontWeight: 'bold', fontSize:'18pt', paddingBottom: '5vh'}}>Sign In</p>
                    <Form>
                        <FormGroup>
                            <p >
                                We've sent the code to Your Phone Number. Please enter the code below.
                            </p>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup style={{border: 0, borderBottom: '1px solid black'}}>
                                <Input 
                                    name="password" 
                                    type="text" 
                                    maxLength="6" 
                                    value={props.code} 
                                    onChange={(e)=>{
                                        props.changeCode(e);
                                        isDisabled();
                                    }} 
                                    style={{
                                        textAlign:"center",
                                        background: '#222',
                                        color : 'white'
                                    }}/>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </Col>
            </Col>
        </div>
    )
}


export default memo(InputVerif);