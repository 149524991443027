export default (initState = [], action) => {
    switch(action.type) {
        case "FETCH_CONTACT" : {
            return {
                ...action.payload
            }
        }
        case "CLEAR_CONTACT" : {
            return {
                initState
            }
        }
        default : {
            return initState;
        }
    }
}