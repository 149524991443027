import React, {memo} from 'react';
import {
    Form, FormGroup, InputGroup, Button, Col, Input, HelpBlock
} from 'rsuite'

const InputPhone = (props) => {
    const widthComponent = props.mobile === true ? "100%":400;
    return (
        <div style={{display:props.show}}>
            <Col md={24} xs={24} sm={24} style={{zIndex: 2, width:widthComponent}}>
                <Col md={24} xs={24} sm={24}>
                    <Button appearance="primary" id="onSubmit" style={{float: "right", zIndex: 2, background: "none", fontWeight: "bold", fontSize: '13pt'}} onClick={props._onSubmit} disabled={props.disabled}>Sign in</Button>
                </Col>
                <Col md={24} xs={24} sm={24} style={{background: '#222', padding: '50px', borderRadius: "5px", boxShadow: " 0px 0px 6px 0px rgba(0,0,0,0.75)", MozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)", WebkitBoxShadow : "0px 0px 6px 0px rgba(0,0,0,0.75)"}}>
                    <p style={{fontWeight: 'bold', fontSize:'18pt', paddingBottom: '5vh'}}>Sign In</p>
                    <Form onSubmit={props._onSubmit}>
                        <FormGroup>
                            <p>
                                Please enter your phone number to sign in on this app
                            </p>
                        </FormGroup>
                        <FormGroup style={{background:'#222'}}>
                            <InputGroup style={{borderRadius:0, border: 0, borderBottom: '1px solid black'}}>
                                <InputGroup.Addon style={{background:"#222", color: 'white'}}>+62</InputGroup.Addon>
                                <Input name="password" type="text" value={props.phone} onChange={(e)=>{props.changePhone(e)}} style={{background:"#222", color: 'white'}}/>
                            </InputGroup>
                            <HelpBlock style={{color : 'red', display : `${props.notif.phoneNotif}`}}>Input is not an international phone number!</HelpBlock>
                        </FormGroup>
                    </Form>
                </Col>
            </Col>
        </div>
    )
}

export default memo(InputPhone);