import {faHome} from '@fortawesome/free-solid-svg-icons';

import Login from 'containers/LandingPage/views';
import Dashboard from 'containers/DashboardPage/views';


export default {
    public : [{
        path : "/login",
        component: Login,
        exact:true
    }],
    private : [{
        path : "/",
        component: Dashboard,
        icon : faHome,
        key : "dashboard",
        text : "Dashboard",
        exact:true,
        role : ["agent", "marketing", "trainer"],
    }]
}