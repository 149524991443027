import React, {memo} from 'react';
import {
    Col
} from 'rsuite';
import {connect} from 'react-redux';
import * as action from '../action';

const ContactList = (props) => {
    console.log("ContactList");
    const getUserChat = () => {
        props.STOP_LISTENER(props.phone);
        props.FETCH_USER_CHAT(props.phone);
        props.SHOW_CHAT();
    }

    const defaultImg = (ev) => {
        ev.target.src = "/img/avatar/avatar-1.png";
      }
    const img = props.photo ? props.photo : "/img/avatar/avatar-1.png"
    const name = props.name ? props.name : props.phone;
    const unread = props.unread ? props.unread : ""
    return (
        <Col style={{cursor: "pointer", padding: 5, display: "inline-block", width: "100%", position: "relative"}} onClick={getUserChat}>
            <div style={{float: "left", height: "100%"}}>
                <img 
                    src={img}
                    onError={defaultImg}
                    style={{
                        borderRadius: 100,
                        width: 45
                    }}
                    alt="userimg"
                />
            </div>
            <div style={{height: "100%" }}>
                <div style={{float: "left", marginLeft: 4, overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: "nowrap", maxWidth: "50%"}}>
                    <p style={{fontSize:"10pt", overflow: "hidden", textOverflow: 'ellipsis', }}>{name}</p>
                    <p style={{maxWidth: "115px", maxHeight: 20, overflow: "hidden", textOverflow: 'ellipsis'}}>{props.lastMessage}</p>
                </div>
                <div style={{float : "right", maxWidth: "50%"}}>
                    <p style={{fontSize:"9pt"}}>{props.lastTime}</p>
                    <p style={{float: "right", backgroud: "red", color: "red", fontWeight:"bold"}}>{unread}</p>
                </div>
            </div>
        </Col>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        STOP_LISTENER : (userid) => dispatch(action.destroyChatList(userid)),
        FETCH_USER_CHAT : (userid) => dispatch(action.getChatList(userid)),
        SHOW_CHAT : () => dispatch(action.showComponentChat()),
    }
}

export default connect(null, mapDispatchToProps)(memo(ContactList));