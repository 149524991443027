import {CHANGE_PHONE, CHANGE_CODE, LOGIN_SUBMIT} from "../actions/types";

const userData = {
    phone : "",
    code : ""
}

export default (state = userData, action) => {
    switch(action.type){
        case CHANGE_PHONE : {
            return {
                ...state,
                phone : action.payload
            }
        }
        case CHANGE_CODE : {
            return {
                ...state,
                username : action.payload
            }
        }
        case LOGIN_SUBMIT : {
            alert("Login Success");
            return {
                ...state
            }
        }
        default : {
            return state;
        }
    }
}