import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

//import reducer from any source
import authReducer from 'containers/LandingPage/reducers/auth';
import landingPage from 'containers/LandingPage/reducers';

import contactReducer from 'containers/DashboardPage/reducers/contactList';
import chatReducer from 'containers/DashboardPage/reducers/chatList';
import styleDashboarReducer from 'containers/DashboardPage/reducers/styleReducer';
import contactInDbReducer from 'containers/DashboardPage/reducers/contactInDb';

// import storage from "config/persistState";
// const Storage = new storage();


const persistConfig = {
    key: 'root',
    storage,
}

const combinedReducer = combineReducers({
    auth : authReducer,
    contact : contactReducer,
    listContactInDb : contactInDbReducer,
    chat : chatReducer,
    dashboard : styleDashboarReducer,
    landingPage
})

const persistedReducer = persistReducer(persistConfig, combinedReducer)

const store = createStore(
    persistedReducer, 
    compose(
        applyMiddleware(thunk),
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
    )
)

let persistor = persistStore(store)
// store.subscribe(() => {
//     const state = store.getState();
//     localStorage.setItem("state", JSON.stringify(state));
// })

export default {
    store,
    persistor
};