import React from 'react';
import {withRouter, Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';

import { checkLogin } from 'containers/LandingPage/actions/index';

const mapStateToProps = state => {
    return {
        auth : state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        isLogin : dispatch(checkLogin())
    }
}

const PrivateRoute = connect(mapStateToProps, mapDispatchToProps)(
    withRouter(({component : Component, ...rest}) => {
        const state = rest;
        console.log(state);
        return (
            <Route
                {...state}
                render={props =>
                    state.auth.user ? (
                    <Component isMobile={state.isMobile}/>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
                }
            />
        );
        
    })
);

const PublicRoute = connect(mapStateToProps, mapDispatchToProps)(
    withRouter(({component : Component, ...rest}) => {
        const state = rest;
        console.log(state);
        return (
            <Route
                {...state}
                render={props =>
                    !state.auth.user ? (
                    <Component isMobile={state.isMobile}/>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                )
                }
            />
        );
        
    })
);

export {
    PublicRoute as Public,
    PrivateRoute as Private
}

