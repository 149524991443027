export default (initState = {}, action) => {
    switch(action.type) {
        case "SHOW_CONTACT_LIST" : {
            return {
                ...action.payload
            }
        }
        case "ADD_CONTACT_TO_CONTACT_LIST" : {
            let newData = Object.assign({}, {...initState, ...action.payload})
            return {
                ...newData
            }
        }
        case "CLEAR_CONTACT_LIST" : {
            return {};
        }
        default : {
            return initState;
        }
    }
}