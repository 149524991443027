export default (initState = {}, action) => {
    switch(action.type){
        case "FETCH_USER" : {
            return {
                user : action.payload
            }
        }
        case "LOGOUT" : {
            return {}
        }
        default : {
            return initState
        }
    }
}