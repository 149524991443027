import React, {memo} from 'react';
import Router from 'config/routes';
import {Route, Switch} from 'react-router-dom';
import {Private, Public} from './components/router';
import NotFound from 'containers/NotFound/views';
const App = (props) => {
  return (
    <div>
        <Switch>
          {
            Router.public.map((data, i) => {
              return <Public path={data.path} component={data.component} exact={data.exact} key={i}/>
            })
          }
          {
            Router.private.map((data, i) => {
              return <Private  path={data.path} component={data.component} exact={data.exact} key={i}/>
            })
          }
          <Route path="*" exact={true} component={NotFound}/>
        </Switch>        
    </div>
  );
}

export default memo(App);
