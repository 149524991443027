import React, {memo, useState, useRef, useEffect} from 'react';
import {
    Icon, Modal, Row, Col, Container, Drawer, Paragraph, Button
} from 'rsuite';
import firebase from 'config/firebase';
import * as action from '../action';
import {connect} from 'react-redux';
import "../styles/styles.css";

const Input = memo((props) => {
    return (
        <textarea
            onChange={props.onChange}
            placeholder='try writing some lines'
            value={props.value}
            onKeyPress={props.onKeyPress}
            style={props.style}
        />
    )
})

const InputMessage = (props) => {
    console.log("InputMessage");
    const [message, setmessage] = useState('');
    const [imgMessage, setImgMessage] = useState('');
    const [img, setimg] = useState('');
    // const Message = useRef();
    const ImgMessage = useRef();
    const SelectedFile = useRef();

    useEffect(() => {
        console.log("use state");
        setmessage('');
        setImgMessage('');
        setimg('');
        console.log(message);
    }, [props.phone]);


    const textEnter = async (e) => {
        // const message = this.state.message;
        if ((!(e.key === 'Enter' && e.shiftKey) && e.key === 'Enter' && e.target.value.trim() !== "") || e === "click") {
            if(e !== "click"){
                e.preventDefault();
            }
            // let message = Message.value
            // Message.value = ""
            let msg = message;
            const userAuth = firebase.auth();
            const phone = userAuth.currentUser.phoneNumber;
            setmessage("");
            setTimeout(() => {
                props.APPEND_MESSAGE({
                    message: `${msg}\n\n_cs: ${localStorage.getItem("csName")}`,
                    sender : phone,
                    timeMillis : null,
                    timeMillisMin : null,
                    userType : 'cs',
                });
            }, 10);
            setTimeout(() => {
                sendMessage(message);
            }, 1500);
        }
    }

    const sendMessage = async (message) => {
        if(img !== ''){
            await props.SEND_IMAGE(props.phone, img, imgMessage);
            await props.CLOSE_IMAGE();
            setmessage('');
            setImgMessage('');
            setimg('');
        } else {
            setmessage('');
            setImgMessage('');
            setimg('');
            await props.SEND_MESSAGE(props.phone, message)
        }

    }

    const showImage = (e) => {
        const img = e.target.files[0];
        setimg(img);
        props.SHOW_IMAGE(URL.createObjectURL(img))
    }

    const changeMessage = async (e) => {
        setmessage(e.target.value)
    }

    const changeImgMessage = e => {
        setImgMessage(e.target.value);
    }

    return (
        <div className="footer" style={{position: "absolute", marginTop: 30, bottom: 9, width: "100%", height: "15vh", padding: "20px 20px 0 20px", overflow: "hidden", background: "#222", boxShadow: " 0px 0px 6px 0px rgba(0,0,0,0.75)", MozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)", WebkitBoxShadow : "0px 0px 6px 0px rgba(0,0,0,0.75)"}}>
            <Drawer full placement={"bottom"} show={props.isShowImg} onHide={props.CLOSE_IMAGE} style={{backgroundColor: "#202020"}}>
                <Drawer.Body style={{backgroundColor: "#202020"}}>
                    <img
                        style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "50%"
                        }}
                        src={props.imgUrl}
                        alt="Preview img"
                    />
                </Drawer.Body>
                <Drawer.Footer>
                    <>
                    <Input
                        onChange={changeImgMessage}
                        value={imgMessage}
                        // onKeyPress={textEnter}
                        style={{resize: "none", width: "90%", height: 50, maxHeight: 100, outline: "none", border: "none", float: "left", color : "white", background : "black"}}
                    />
                    <div className="option" style={{width: "10%", height: 50, maxHeight: 100, float: "left"}}>
                        <div style={{float: "left", padding:10, cursor: "pointer", color: "black"}} onClick={() => sendMessage()}>
                            <Icon
                                icon="send"
                                size="2x"
                                style={{fontSize: 18, color: "white"}}
                            />
                        </div>
                    </div>
                    </>
                </Drawer.Footer>
            </Drawer>
            {/* <Modal full show={props.isShowImg} onHide={props.CLOSE_IMAGE} style={{position: "relative"}}>
                <Modal.Body style={{position:"relative"}}>
                    
                </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
            </Modal> */}
            <Container>
                <Row>
                    <Col md={21} sm={20} xs={18}>
                        <Input
                            onChange={changeMessage}
                            value={message}
                            onKeyPress={textEnter}
                            style={{resize: "none", width: "100%", height: 50, maxHeight: 100, outline: "none", border: "none", float: "left", background: "none", borderBottom: "1px solid black"}}
                        />
                    </Col>
                    <Col md={3} sm={4} xs={6}>
                        <Container>
                            <Row>
                                <Col style={{display:"inline"}} md={12} sm={12} xs={12}>
                                    <input type="file" style={{display: "none"}} ref={SelectedFile} onChange={(e) => showImage(e)}/>
                                    <span style={{float: "left", padding:10}} onClick={e => SelectedFile.current.click()}>
                                        <Icon
                                            icon="attachment"
                                            size="2x"
                                            style={{fontSize: 18}}
                                        />
                                    </span>
                                </Col>
                                <Col style={{display:"inline"}} md={12} sm={12} xs={12}>
                                    <span style={{float: "left", padding:10, cursor: "pointer"}} onClick={() => textEnter("click")}>
                                        <Icon
                                            icon="send"
                                            size="2x"
                                            style={{fontSize: 18}}
                                        />
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


const mapStateToProps = ({chat}) => {
    return {
        phone : chat.phone,
        isShowImg : chat.showImage,
        imgUrl : chat.imgUrl
    }
}

const mapDispatchToProps = dispatch => {
    return {
        SEND_MESSAGE : (userid, message) => dispatch(action.sendMessage(userid, message)),
        SHOW_IMAGE : (url) => dispatch(action.showImage(url)),
        CLOSE_IMAGE : () => dispatch(action.hideImage()),
        SEND_IMAGE : (userid, img, message) => dispatch(action.sendImage(userid, img, message)),
        APPEND_MESSAGE : (data) => dispatch(action.appendNewChat(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(InputMessage));