const state = {
    userid : '',
    phone : '',
    isShow : false,
    lastChat : false,
    showImage : false,
    imgUrl : '',
    data : {}
}

export default (initState = state, action) => {
    switch(action.type){
        case "SHOW_CHAT" : {
            return {
                ...initState,
                isShow : true
            }
        }
        case "HIDE_CHAT" : {
            return {
                ...initState,
                isShow : false
            }
        }
        case "FETCH_USER_NAME_INFO" : {
            return {
                ...initState,
                userid : action.payload.userid,
                phone : action.payload.phone
            }
        }
        case "SHOW_IMAGE":{
            return {
                ...initState,
                showImage : true,
                imgUrl : action.url
            }
        }
        case "HIDE_IMAGE":{
            return {
                ...initState,
                showImage : false,
                imgUrl : ""
            }
        }
        case "FETCH_USER_CHAT" : {
            return {
                ...initState,
                data : {
                    ...action.data
                }
            }
        }
        case "APPEND_NEW_USER_CHAT" : {
            let newData = Object.assign({}, {...initState.data, ...{"key" : action.data}})
            return {
                ...initState,
                data : newData
            }
        }
        case "FETCH_NEXT_CHAT" : {
            let newData = Object.assign({} ,{...action.data.val, ...initState.data});
            return {
                ...initState,
                // lastChat : action.lastChat,
                data : newData
            }
        }
        case "GET_USER_NEW_CHAT" : {
            Object.assign({
                ...initState,
                data : {
                    ...action.data
                }
            })
            return{
                initState,
            }
        }
        case "CLEAR_CHAT_FROM_VIEW" : {
            return state;
        }
        default : return initState
    } 
}