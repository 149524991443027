import {CHANGE_PHONE, CHANGE_CODE} from "./types";
import firebase from 'config/firebase'

export const changePhone = (phone) => {
    return {
        type : CHANGE_PHONE,
        payload : phone
    }
}

export const changeCode = (code) => {
    return {
        type : CHANGE_CODE,
        payload : code
    }
}

export const checkLogin = () => {
    return {
        type : "CHECK_LOGIN"
    }
}

export const userList = () => {
    return dispatch => {
        firebase
            .database()
            .ref('contact')
            .once("value")
    }
}

export const login = () => {
    return dispatch => {
        firebase
            .auth()
            .onAuthStateChanged((user) => {
                if (user) {
                // User is signed in.
                    return dispatch({
                        type : "FETCH_USER",
                        payload: user
                    })
                } else {
                // No user is signed in.
                    return dispatch({
                        type : "FETCH_USER",
                        payload : null
                    })
                }
            });
    }
}

export const checkUserList = () => {
    return dispatch => {
        firebase
            .storage()
    }
}

export const Logout = () => {
    return dispatch => {
        let user = firebase.auth().currentUser;
        console.log(user);
        const phone = user.phoneNumber.replace("+", "");
        firebase
            .firestore()
            .collection("customer_support")
            .where("phone", "array-contains", phone)
            .get()
            .then((sn) => {
                sn.forEach(function(doc) {
                    firebase
                    .firestore()
                    .collection("customer_support")
                    .doc(doc.id)
                    .update({
                        token : firebase.firestore.FieldValue.arrayRemove(localStorage.getItem("token"))
                    })
                });
            })
        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch({
                    type : "LOGOUT"
                })
            })
    }
}