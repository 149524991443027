import React, {Suspense, useState} from 'react';
import {Container, Row, Loader} from 'rsuite';
import {connect} from 'react-redux'
import SearchContact from './SearchContact';
import * as action from '../action/index'
import ScrollViewport from 'react-scroll-viewport';
const ContactList = React.lazy(() => import('./ContantList'));

const Contact = (props) => {
    console.log("Contact");
    useState(() => {
        props.fetchContact();
    }, [props.contact])
    return (
        <Container style={{textOverflow: "ellipsis"}}>
            <Row style={{background:"none"}}>
                <SearchContact />
            </Row>
            <Row>
                <Suspense fallback={<div style={{minHeight : "100vh", position: "relative", top : "30vh", left: "45%"}}><Loader size="lg" /></div>}>
                    <ScrollViewport class="list" sync>
                    {
                        Object.keys(props.contact).map((data, key) => {
                            const date = new Date(props.contact[data].lastChatTime);
                            const lastTimeChat = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
                            return (
                                <ContactList 
                                    name={props.contact[data].name} 
                                    phone={props.contact[data].phone} 
                                    photo={props.contact[data].photo} 
                                    lastTime={props.contact[data].lastChatTime? lastTimeChat: ""}
                                    lastMessage={props.contact[data].lastChatMessage}
                                    unread={props.contact[data].unread}
                                    key={data}
                                />
                            )
                        })
                    }
                    </ScrollViewport>
                </Suspense>
            </Row>
        </Container>
    );
}

const mapStateToProps = ({contact, chat}) => {
    return {
        contact : contact,
    }
}

const mapDispatchToState = (dispatch) => {
    return {
        fetchContact : () => dispatch(action.getContactList())
    }
}

export default connect(mapStateToProps, mapDispatchToState)(Contact);