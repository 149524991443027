import React from 'react';
import {Container, FlexboxGrid} from 'rsuite';

const NotFound = () => {
    return (
        <Container style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24} align="middle" justify="center" style={{height: '100vh'}}>
                    <div>
                        <img src="/img/isometric/404.png" style={{height: 'auto', width: '100%', maxWidth: 512, margin: 'auto auto'}} alt="Not Found"/>
                    </div>
                    <div>
                        <p style={{textAlign:"center", fontSize:'16pt', fontWeight: "bold"}}>Your request URL is not found</p>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
}

export default NotFound;