const initState = {
    showContact : true,
    showChat : false
}

export default (state = initState, action) => {
    switch(action.type){
        case "showContact" : {
            return {
                showContact : true,
                showChat : false
            };
        }
        case "showChat" : {
            return {
                showContact : false,
                showChat : true
            };
        }
        default : {
            return state;
        }
    }
}