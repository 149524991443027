import React, {Suspense, memo, useEffect} from 'react';
import {Loader} from 'rsuite';
import {connect} from 'react-redux';

const Chatlist = React.lazy(() => import('./chatList'));

function ShowMessage(props){
    console.log("SHowMessage");
    console.log(props);
    useEffect(() => {
        
    }, [props]);
    if(props.isChatZero !== undefined){
        if(props.isChatZero > 0){
            return (
                <Suspense fallback={<div style={{minHeight : "100vh", position: "relative", top : "30vh", left: "45%"}}><Loader size="lg" /></div>}>
                    <Chatlist 
                        style={{overflowY: "scroll", overflowX : "hidden", maxHeight : "80vh", margin: 0, paddingLeft: 20, paddingRight : 20}}
                        ismobile={props.ismobile}
                    />
                </Suspense>
            )
        } else {
            return <div style={{minHeight : "100vh", position: "relative", top : "30vh", left: "45%"}}><Loader size="lg" /></div>
        }
    } else {
        return null
    }
}

const mapStateToProps = ({chat}) => {
    return {
        isChatZero: Object.keys(chat.data).length
    }
}

export default memo(connect(mapStateToProps, null)(ShowMessage));