import React, {useState, useEffect} from 'react';

import { 
    Container, Grid, Content
} from 'rsuite';
import PushNotification from 'config/firebase/push-notification';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import MessageComponent from '../components/MessageComponent';
import ModalContacts from '../components/ModalContacts';
import Contacts from '../components/Contact';
import * as pageAction from '../action';
import '../styles/styles.css';
import '../styles/footer.css';

import ChatHeader from '../components/ChatHeader';

const DashboardPage = (props) => {
    // const [dropdown, setdropdown] = useState('none');
    const [searchContactShow, setsearchContactShow] = useState(false);
    const [isMobile, setisMobile] = useState(false);

    useEffect(() => {
        if(!localStorage.getItem("token")){
            PushNotification()
        }
        checkIsMobileDevice();
        // openDropDown();
    }, [props])

    useEffect(() => {
        props.clearChat();
    }, [])

    const checkIsMobileDevice = () => {
        const isMobile = window.innerWidth <= 630;
        if(isMobile){
            setisMobile(true);
        } else {
            setisMobile(false);
        }
    }

    const hideContactsShow = () => {
        setsearchContactShow(false);
    }

    const showContactsShow = () => {
        setsearchContactShow(true);
    }


    // const openDropDown = () => {
    //     setdropdown('inline-block');
    // }

    return isMobile === true ? 
    (
        <div className="show-container" style={{background: 'black', width: "100vw", height: '100vh'}}>
            <ModalContacts searchContactShow={searchContactShow} onHide={hideContactsShow}/>
            <Grid fluid>
                <Container style={{width: "100%", background: '#222'}}>
                    <div>
                        <Container>
                            <ChatHeader
                                addChat={showContactsShow}
                                isMobile ={isMobile}
                            />
                        </Container>
                        <Container>
                            <Content>
                                {
                                    props.showComponentContact === true ? 
                                    (
                                        <div style={{overflow: "auto", position: "relative", width: "100%", height: "95vh", maxHeight: "100vh", background: "#202020", float : "left", padding: "0 15px 0 15px", boxShadow: " 0px 0px 6px 0px rgba(0,0,0,0.75)", MozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)", WebkitBoxShadow : "0px 0px 6px 0px rgba(0,0,0,0.75)",}}>
                                            <Contacts />
                                        </div>
                                    ) : 
                                    (
                                        <div style={{overflow : "hidden", width: "100%", height: "100vh", maxHeight: "95vh", position: "relative", float: "left"}}>
                                            <MessageComponent ismobile={isMobile}/>
                                        </div>
                                    )
                                }
                            </Content>
                        </Container>
                    </div>
                </Container>
            </Grid>
        </div>
    )
        : 
    (
        <div className="show-container" style={{background: 'black', width: "100vw", height: '100vh'}}>
            <ModalContacts searchContactShow={searchContactShow} onHide={hideContactsShow}/>
            <Grid fluid>
                <Container style={{width: "100%", background: '#222'}}>
                    <div>
                        <Container>
                            <ChatHeader
                                addChat={showContactsShow}
                                isMobile ={isMobile}
                            />
                        </Container>
                        <Container>
                            <Content>
                                <div style={{overflow: "auto", position: "relative", width: "30%", height: "95vh", maxHeight: "100vh", background: "#202020", float : "left", padding: "0 15px 0 15px", boxShadow: " 0px 0px 6px 0px rgba(0,0,0,0.75)", MozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)", WebkitBoxShadow : "0px 0px 6px 0px rgba(0,0,0,0.75)",}}>
                                    <Contacts />
                                </div>
                                <div style={{overflow : "hidden", width: "70%", height: "100vh", maxHeight: "95vh", position: "relative", float: "left"}}>
                                    <MessageComponent ismobile={isMobile}/>
                                </div>
                            </Content>
                        </Container>
                    </div>
                </Container>
            </Grid>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userid : state.chat.userid,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearChat : () => dispatch(pageAction.clearChatFromView())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));