import React, {useEffect, useState, memo} from 'react';
import {connect} from 'react-redux';
import * as action from '../action';
import {InputGroup, Input, Icon} from 'rsuite';

const SearchContact = (props) => {
    console.log("SearchContact");
    console.log(props);
    const [contactSearch, setcontactSearch] = useState('');
    useEffect(() => {
        // props.FETCH_CONTACT();
    }, []);

    useEffect(() => {

    }, [contactSearch])

    return (
        <InputGroup style={{border:'none', color : "white", background: "#575757", outline: "none"}}>
            <Input style={{background: "#202020", zIndex: 0, color: "white", borderBottom:"1px solid black"}} value={contactSearch} onChange={e => setcontactSearch(e)} onPressEnter={() => props.FETCH_CONTACT(contactSearch)}/>
            <InputGroup.Addon style={{background:"#202020"}} onClick={() => props.FETCH_CONTACT(contactSearch)}>
                <Icon icon="search" style={{background:"#202020", color: "white"}}/>
            </InputGroup.Addon>
        </InputGroup>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        FETCH_CONTACT : (name = null) => dispatch(action.getContactList(name)),
    }
}

export default connect(null, mapDispatchToProps)(memo(SearchContact));