const config = {
    apiKey: "AIzaSyCa6NOkiWRF1GVUSP-YgE_bnZxYJ7s03lM",
    authDomain: "chat-support-102fc.firebaseapp.com",
    databaseURL: "https://chat-support-102fc.firebaseio.com",
    projectId: "chat-support-102fc",
    storageBucket: "chat-support-102fc.appspot.com",
    messagingSenderId: "554470375547",
    appId: "1:554470375547:web:f5f445870790d46eeb22de"
};

export default config;