import firebase from './index';
const messaging = firebase.messaging();
const initFirebaseMessagingRegistration = () => {
   messaging
       .requestPermission()
       .then(function () {
           console.log("Got notification permission");
           return messaging.getToken();
       })
       .then(function (token) {
           // print the token on the HTML page
           // tokenElement.innerHTML = "Token is " + token;
            console.log(token);
         //   firebase
         //    .firestore()
            let user = firebase.auth().currentUser;
            console.log(user);
            const phone = user.phoneNumber.replace("+", "");
            firebase
               .firestore()
               .collection("customer_support")
               .where("phone", "array-contains", phone)
               .get()
               .then((sn) => {
                  sn.forEach(function(doc) {
                     const userData = doc.data();
                     userData.token.push(token)
                     firebase
                        .firestore()
                        .collection("customer_support")
                        .doc(doc.id)
                        .update({
                           token : firebase.firestore.FieldValue.arrayUnion(token)
                        })
                  });
               })
            localStorage.setItem("token", token);
       })
       .catch(function (err) {
           // errorElement.innerHTML = "Error: " + err;
           console.log("Didn't get notification permission", err);
       });
}
messaging.onMessage(function (payload) {
   console.log(payload);
   // notificationElement.innerHTML = notificationElement.innerHTML + " " + payload.data.notification;
});
messaging.onTokenRefresh(function () {
   messaging.getToken()
       .then(function (refreshedToken) {
           console.log('Token refreshed.');
           
       }).catch(function (err) {
           
           
       });
});

export default initFirebaseMessagingRegistration;